<template>
  <div>
    <div v-show="!showAdd">
      <el-row :gutter="10">
        <el-col :span="12"
                style="margin-bottom: 10px;">
          <el-button type="primary"
                     @click="addDetail">模板详情录入</el-button>
        </el-col>
      </el-row>

      <Table ref="TablesRef"
             :tableData="tableData" />
      <DetailDialog ref="detailDialogRef" />
    </div>
    <div v-if="showAdd">
      <AddDetail :categoryList='categoryList'
                 :typeList="typeList"
                 :knowledgeTree="knowledgeTree" />
    </div>
  </div>
</template>

<script>
import { getRuleDetail } from '@/api/paperRuleTemplate.js'
import DetailDialog from './component/DetailDialog.vue'
import AddDetail from './component/addDetail.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'paper_rule_id', align: 'center', label: '规则模板ID', width: '100' },
        { prop: 'group_id', align: 'center', label: '组别ID', width: '90' },
        { prop: 'group_score', align: 'center', label: '组别分数', width: '100' },
        { prop: 'question_number', align: 'center', label: '题号', width: '90' },
        { prop: 'question_type_id', align: 'center', label: '题目类型', isStatus: true },
        { prop: 'question_category', align: 'center', label: '题目类别', isStatus: true },
        { prop: 'correlation_knowledge', align: 'center', label: '关联知识点', isStatus: true },
        { prop: 'correlation_number', align: 'center', label: '关联题号' },
        { prop: 'max_score', align: 'center', label: '最大分数', width: '90' },
        { prop: 'min_score', align: 'center', label: '最小分数', width: '90' },
        { prop: 'fixed_score', align: 'center', label: '固定分数', width: '90' },
      ],
      tableData: [],
      paper_rule_id: '',
      subject_id: '',
      paper_district_id: '',
      showAdd: false,
      categoryList: [],
      typeList: [],
      knowledgeTree: [],
      knowledgeFlat: []
    }
  },
  components: {
    DetailDialog, AddDetail
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'question_type_id' || prop == 'question_category') {
        return 'text_warp'
      }

    },
    getValue (prop, value) {
      if (prop == 'question_type_id') {
        let arr = []
        let typeArr = value.split(',').map(item => item = Number(item))
        for (let item in this.typeList) {
          if (typeArr.indexOf(this.typeList[item].question_type_id) != -1) {
            arr.push(this.typeList[item].question_type_name)
          }
        }
        return arr.join(',')
      }

      if (prop == 'question_category') {
        let arr = []
        let categoryArr = value.split(',').map(item => item = Number(item))
        for (let item in this.categoryList) {
          if (categoryArr.indexOf(this.categoryList[item].question_category_id) != -1) {
            arr.push(this.categoryList[item].question_category_name)
          }
        }
        return arr.join(',')
      }
      if (prop == 'correlation_knowledge') {
        let arr = []
        let knowledgeArr = value.split(',').map(item => item = Number(item))
        for (let item in knowledgeArr) {
          // console.log(item, 'item')
          if (this.knowledgeFlat.filter(i => i.knowledge_id == knowledgeArr[item]).length > 0) {
            arr.push(this.knowledgeFlat.filter(i => i.knowledge_id == knowledgeArr[item])[0].knowledge_desc)
          }
        }
        return arr.join(',')
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    async initData () {
      this.paper_rule_id = this.$route.query.paper_rule_id
      this.subject_id = this.$route.query.subject_id
      this.paper_district_id = this.$route.query.paper_district_id
      const { data } = await getRuleDetail({
        paper_rule_id: this.paper_rule_id
      })
      this.tableData = data.list
    },
    addDetail () {
      // let form = {
      //   paper_rule_id: this.paper_rule_id,
      //   subject_id: this.subject_id,
      //   paper_district_id: this.paper_district_id
      // }
      // this.$refs.detailDialogRef.rowInfo = form
      // this.$refs.detailDialogRef.dialogVisible = true
      this.showAdd = true
    },

    // 获取题目类型
    getPaperType () {
      // console.log(this.rowInfo.subject_id, "aa")
      let params = {
        subject_id: this.$route.query.subject_id,
        paper_district_id: this.$route.query.paper_district_id
      }
      this.$http({
        url: '/api/v2/paper/question_district_type',
        method: 'get',
        params
      }).then(res => {
        this.typeList = res.data
      })
    },

    // 根据科目，地区获取题目类别
    getPaperCategory () {
      let params = {
        subject_id: this.$route.query.subject_id,
        paper_district_id: this.$route.query.paper_district_id
      }
      this.$http({
        url: '/api/v2/paper/question_district_category',
        method: 'get',
        params
      }).then(res => {
        this.categoryList = res.data
      })
    },
    getFlatArr (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.getFlatArr(list[i].child))
        }
      }
      return allList
    },
    getKnowledgeTree (subject_id) {
      this.$http({
        url: '/api/v2/public/knowledge_tree',
        method: 'get',
        params: {
          subject_id: this.$route.query.subject_id,
        }
      }).then(res => {
        this.knowledgeTree = res.data
        this.knowledgeFlat = this.getFlatArr(this.knowledgeTree)
      })
    },
  },
  mounted () {
    this.getPaperCategory()
    this.getPaperType()
    this.setTablesColums()
    this.getKnowledgeTree()
    this.initData()
  }
}

</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.el-button {
  padding: 0 15px;
  width: auto;
}
</style>