<template>
  <div>
    <div class="btns">
      <el-button type='primary'
                 @click="addTopic(0)">录入非组别题目</el-button>
      <el-button type='primary'
                 @click="addTopic(1)">创建组别</el-button>
    </div>
    <div class="main_list_wrap">

      <div style="padding:0 10px;margin:0 10px 10px;border-bottom:1px solid #ddd"
           v-for='(data_item,index) in single_data'
           :key="
           index+'s'">
        <el-form :model="data_item"
                 :ref="'formRefS'+index"
                 :rules="getRules(data_item)"
                 label-width="100px">
          <el-row :gutter="10">
            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="题号"
                            prop="question_number">
                <el-input-number placeholder="请输入题号"
                                 controls-position="right"
                                 :precision='0'
                                 v-model="data_item.question_number"
                                 style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">

              <el-form-item label="题目类型"
                            prop="question_type_id">
                <el-select v-model="data_item.question_type_id"
                           style="width:100%"
                           multiple
                           placeholder="请选择">
                  <el-option v-for="item in typeList"
                             :key="item.question_type_id"
                             :label="item.question_type_name"
                             :value="item.question_type_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="题目类别"
                            prop="question_category">
                <el-select v-model="data_item.question_category"
                           multiple
                           style="width:100%"
                           placeholder="请选择">
                  <el-option v-for="item in categoryList"
                             :key="item.question_category_id"
                             :label="item.question_category_name"
                             :value="item.question_category_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="7"
                    :xl="7"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="关联知识点">
                <el-cascader v-model="data_item.correlation_knowledge"
                             ref="cascaderRef"
                             :options="knowledgeTree"
                             :props="knowledgeProps"
                             :key='data_item.paper_rule_question_id'
                             placeholder="选择"
                             collapse-tags
                             filterable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="关联题号">
                <el-input v-model="data_item.correlation_number"
                          placeholder="请输入如1,2,3"></el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="固定分数"
                            prop="fixed_score">
                <el-input placeholder="请输入固定分数"
                          v-model="data_item.fixed_score"
                          style="width:100%" />
              </el-form-item>
            </el-col>

            <el-col :span="4"
                    style="margin-bottom:10px;margin-left:18px">
              <el-popconfirm confirm-button-text='确定'
                             cancel-button-text='取消'
                             icon="el-icon-info"
                             icon-color="red"
                             title="确定删除？"
                             @confirm="onDel('sigal',index)">
                <!-- @click="onDel('group',index1,index)" -->
                <el-button type='danger'
                           slot="reference">删除题目</el-button>
              </el-popconfirm>

            </el-col>

          </el-row>

        </el-form>

      </div>
      <div class="box"
           v-for='(group_data_item,index) in group_data'
           :key="index+'g'">
        <div style="margin-bottom:10px"> <span style="padding:0 10px;font-weight: 600;"> 组别{{index+1}}</span>
          <el-button @click="addTopic(1,index+1)">录入组别题目</el-button>
        </div>

        <el-row :gutter="10">
          <el-form label-width="100px"
                   style="width:100%">
            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="组别ID"
                            prop="group_id">
                <el-input placeholder="请输入总分组别ID"
                          v-model="group_ids[index].group_id"
                          style="width:100%" />
              </el-form-item>
            </el-col>

            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="组别总分"
                            prop="group_score">
                <el-input placeholder="请输入组别总分"
                          v-model="group_ids[index].group_score"
                          style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :lg="5"
                    :xl="5"
                    :md="8"
                    :sm="12"
                    :xs="12">
              <el-form-item label="同一题头"
                            prop="group_score">
                <el-switch v-model="group_ids[index].is_dati_group"
                           :active-value="1"
                           :inactive-value="0"
                           active-text="是"
                           inactive-text="否"
                           active-color="#409EFF"
                           inactive-color="#DBDFE6">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>

        <div v-for='(data_item,index1) in group_data_item'
             :key="index1+'gd'"
             style="border-bottom:1px dashed #ddd;padding-top:10px">
          <el-form :model="data_item"
                   :ref="'formRefG'+index1+index"
                   :rules="getRules1(data_item)"
                   label-width="100px">
            <el-row :gutter="10">

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="题号"
                              prop="question_number">
                  <el-input-number placeholder="请输入题号"
                                   :precision='0'
                                   controls-position="right"
                                   v-model="data_item.question_number"
                                   style="width:100%" />
                </el-form-item>
              </el-col>

              <!-- <el-col :span="5">
              <el-form-item label="组别ID"
                            prop="group_id">
                <el-input placeholder="请输入总分组别ID"
                          v-model="data_item.group_id"
                          style="width:100%" />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="组别总分"
                            prop="group_score">
                <el-input placeholder="请输入总分组别ID"
                          v-model="data_item.group_score"
                          style="width:100%" />
              </el-form-item>
            </el-col> -->

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="题目类型"
                              prop="question_type_id">
                  <el-select v-model="data_item.question_type_id"
                             style="width:100%"
                             multiple
                             placeholder="请选择">
                    <el-option v-for="item in typeList"
                               :key="item.question_type_id"
                               :label="item.question_type_name"
                               :value="item.question_type_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="题目类别"
                              prop="question_category">
                  <el-select v-model="data_item.question_category"
                             multiple
                             style="width:100%"
                             placeholder="请选择">
                    <el-option v-for="item in categoryList"
                               :key="item.question_category_id"
                               :label="item.question_category_name"
                               :value="item.question_category_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="7"
                      :xl="7"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="关联知识点">
                  <el-cascader v-model="data_item.correlation_knowledge"
                               ref="cascaderRef"
                               :options="knowledgeTree"
                               :props="knowledgeProps"
                               :key='data_item.paper_rule_question_id'
                               placeholder="选择"
                               collapse-tags
                               filterable></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="关联题号">
                  <el-input v-model="data_item.correlation_number"
                            placeholder="请输入如1,2,3"></el-input>
                </el-form-item>
              </el-col>

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="固定分数"
                              prop="fixed_score">
                  <el-input placeholder="请输入固定分数"
                            v-model="data_item.fixed_score"
                            style="width:100%" />
                </el-form-item>
              </el-col>

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="最大分数"
                              prop="max_score">
                  <el-input placeholder="请输入最大分数"
                            v-model="data_item.max_score"
                            style="width:100%" />
                </el-form-item>
              </el-col>

              <el-col :lg="5"
                      :xl="5"
                      :md="8"
                      :sm="12"
                      :xs="12">
                <el-form-item label="最小分数"
                              prop="min_score">
                  <el-input placeholder="请输入最小分数"
                            v-model="data_item.min_score"
                            style="width:100%" />
                </el-form-item>
              </el-col>

              <el-col :span="4"
                      style="margin-bottom:10px">
                <el-popconfirm confirm-button-text='确定'
                               cancel-button-text='取消'
                               icon="el-icon-info"
                               icon-color="red"
                               title="确定删除？"
                               @confirm="onDel('group',index1,index)">
                  <!-- @click="onDel('group',index1,index)" -->
                  <el-button type='danger'
                             slot="reference">删除题目</el-button>
                </el-popconfirm>

              </el-col>
            </el-row>

          </el-form>
        </div>
      </div>
    </div>
    <div class="btns1">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="save"
                 type='primary'
                 v-show='group_data.length>0||single_data.length>0'>确定</el-button>
    </div>
  </div>
</template>

<script >
import { getRuleDetail } from '@/api/paperRuleTemplate.js'
import { sortClass } from '@/utils/common.js'
export default {
  data () {
    return {
      question_data: [],
      group_data: [],
      group_ids: [],//存放组别id和总分
      single_data: [],
      // randomId:Math.random(),
      knowledgeProps: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
        emitPath: false,// 只返回该节点的值
        checkStrictly: true
      }
    }
  },
  props: [
    'typeList', //题目类型
    'categoryList',//题目类别
    'knowledgeTree'//关联知识点

  ],
  methods: {
    // 非组别验证规则
    getRules (item) {
      let rules = {
        question_number: [
          { required: true, message: '请输入题号', trigger: 'blur' },
        ],
        question_type_id: [
          { required: !((item.question_category && item.question_category.length) || item.fixed_score), message: '请选择题目类型', trigger: 'change' },
        ],
        question_category: [
          { required: !((item.question_type_id && item.question_type_id.length) || item.fixed_score), message: '请选择题目类别', trigger: 'change' },
        ],
        fixed_score: [
          { required: !((item.question_type_id && item.question_type_id.length) || (item.question_category && item.question_category.length)), message: '固定分数', trigger: 'blur' },
        ]
      }
      return rules
    },
    // 组别验证规则
    getRules1 (item) {
      let rules1 = {
        question_number: [
          { required: true, message: '请输入题号', trigger: 'blur' },
        ],
        question_type_id: [
          { required: !(item.question_category && item.question_category.length), message: '请选择题目类型', trigger: 'change' },
        ],
        question_category: [
          { required: !(item.question_type_id && item.question_type_id.length), message: '请选择题目类别', trigger: 'change' },
        ],
      }
      return rules1

    },

    async initData () {
      const { data } = await getRuleDetail({
        paper_rule_id: this.$route.query.paper_rule_id
      })
      this.question_data = data.list
      if (this.question_data.length) {
        this.initTypeData()
      }
      this.$nextTick(() => {
        // 添加这段代码
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });

    },
    initTypeData () {
      let gropArr = []
      // 根据group_score判断组别/非组别
      this.single_data = this.question_data.filter(item => Number(item.group_id) == 0)
      // 转化类别类型为数字数组
      this.single_data = this.single_data.map((item) => {
        return {
          ...item,
          question_category: item.question_category.split(',').filter(item => item !== '').map(Number),
          question_type_id: item.question_type_id.split(',').filter(item => item !== '').map(Number),
          correlation_knowledge: item.correlation_knowledge.split(',').filter(item => item !== '').map(Number),
        };
      });

      // 转化类别类型为数字数组
      gropArr = this.question_data.filter(item => item.group_id != '0.0')
      gropArr = gropArr.map((item) => {
        return {
          ...item,
          question_category: item.question_category.split(',').filter(item => item !== '').map(Number),
          question_type_id: item.question_type_id.split(',').filter(item => item !== '').map(Number),
          correlation_knowledge: item.correlation_knowledge.split(',').filter(item => item !== '').map(Number),
        }
      })
      // 按group_id分组
      this.group_data = sortClass(gropArr, (item) => {
        return item.group_id;
      })

      for (let i = 0; i < this.group_data.length; i++) {
        this.group_ids.push({
          'group_id': this.group_data[i][0].group_id,
          'group_score': this.group_data[i][0].group_score,
          'is_dati_group': this.group_data[i][0].is_dati_group
        })
        // for (let j = 0; j < group_data[i].length; j++) {
        //   group_data[i][j].question_type_id = group_data[i][j].question_type_id.join()
        //   group_data[i][j].question_category = group_data[i][j].question_category.join()
        // }
      }
    },
    cancel () {
      this.$parent.showAdd = false
    },
    save () {
      let bool = true
      // 非组别题目数据校验
      for (let i = 0; i < this.single_data.length; i++) {

        this.$refs['formRefS' + i][0].validate(val => {
          if (!val) bool = false
        })
      }
      // 组别题目数据校验
      for (let i = 0; i < this.group_data.length; i++) {
        let group_id = this.group_ids[i].group_id
        let group_score = this.group_ids[i].group_score
        let is_dati_group = this.group_ids[i].is_dati_group
        // if (!group_id || Number(group_score) == 0 || !group_score) {
        //   bool = false
        //   this.$message.warning("请检查组别ID和组别总分是否都已填写")
        // }
        if (!group_id) {
          bool = false
          this.$message.warning("请检查组别ID是否都已填写")
        }
        for (let j = 0; j < this.group_data[i].length; j++) {
          this.group_data[i][j].group_id = group_id
          this.group_data[i][j].group_score = group_score
          this.group_data[i][j].is_dati_group = is_dati_group
          this.$refs['formRefG' + j + i][0].validate(val => {
            if (!val) {
              bool = false
            }
            if (this.group_data[i][j].group_id != group_id || this.group_data[i][j].group_score != group_score) {
              bool = false
              this.$message.warning("同一组别题目的组别ID和总分应相同！")
            }
          })
        }
      }
      if (!bool) return

      let single_data = JSON.parse(JSON.stringify(this.single_data))
      let group_data = JSON.parse(JSON.stringify(this.group_data))
      for (let i = 0; i < single_data.length; i++) {
        single_data[i].question_type_id = single_data[i].question_type_id.join()
        single_data[i].question_category = single_data[i].question_category.join()
        single_data[i].correlation_knowledge = single_data[i].correlation_knowledge ? single_data[i].correlation_knowledge.join() : ''
      }
      for (let i = 0; i < group_data.length; i++) {
        for (let j = 0; j < group_data[i].length; j++) {
          group_data[i][j].question_type_id = group_data[i][j].question_type_id.join()
          group_data[i][j].question_category = group_data[i][j].question_category.join()
          group_data[i][j].correlation_knowledge = group_data[i][j].correlation_knowledge ? group_data[i][j].correlation_knowledge.join() : ''
        }
      }
      this.question_data = []
      this.question_data.push(...single_data)

      for (let i = 0; i < group_data.length; i++) {
        this.question_data.push(...group_data[i])
      }
      this.$http({
        url: '/api/v2/paper/rule_question',
        method: 'post',
        data: {
          paper_rule_id: Number(this.$route.query.paper_rule_id),
          question_data: this.question_data
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$parent.initData()
        // this.handleClose()
        this.cancel()
      })
      // this.$parent.showAdd = false
    },
    addTopic (type, index) { //type:1 组别，2 非组别；若有index,则在该组别中添加数据
      let isgroup;
      if (type == 1 && index) {
        // isgroup = true
        this.group_data[index - 1].push({})
      } else if (type == 1) {
        // isgroup = true
        this.group_data.push([{}])
        this.group_ids.push({}) //每新增一个组别，给总分，id数组加一条数据
      } else {
        // isgroup = false
        this.single_data.push({})
      }
    },

    onDel (type, indexChild, indexParent) {
      if (type == 'group') {
        this.group_data[indexParent].splice(indexChild, 1)
        if (this.group_data[indexParent].length == 0) {
          this.group_data.splice(indexParent, 1)
          this.group_ids.splice(indexParent, 1)
        }
      }
      if (type == 'sigal') {
        this.single_data.splice(indexChild, 1)
      }
    }
  },
  mounted () {
    this.initData()
  }

}
</script>

<style lang="scss" scoped>
.main_list_wrap {
  max-height: calc(100vh - 212px);
  overflow: auto;
  overflow-x: hidden;
}
.box {
  // box-shadow: 1px 1px 5px #dfdfdf;
  box-shadow: 1px 1px 5px #9494a9;
  padding: 15px;
  margin: 20px 10px;
}
.el-row {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-button {
  padding: 0 10px;
  width: auto;
}
.btns {
  margin-bottom: 10px;
  margin-top: 10px;
  ::v-deep .el-button {
    padding: 0 10px;
    width: auto;
  }
}
.btns1 {
  display: flex;
  ::v-deep .el-button {
    padding: 0 10px;
    flex: 1;
  }
}
</style>